@font-face {
    font-family: "Abel-Regular";
    src: local("Abel-Regular"),
        url("../fonts/Abel-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Dosis-Bold";
    src: local("Dosis-Bold"),
        url("../fonts/Dosis-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Dosis-SemiBold";
    src: local("Dosis-SemiBold"),
        url("../fonts/Dosis-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Dosis-Medium";
    src: local("Dosis-Medium"),
        url("../fonts/Dosis-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Dosis-Regular";
    src: local("Dosis-Regular"),
        url("../fonts/Dosis-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Lili";
    src: local("Lili"),
        url("../fonts/Lili.ttf") format("truetype");
}

@font-face {
    font-family: "CantedFX-Bold";
    src: local("CantedFX-Bold"),
        url("../fonts/CantedFX-Bold.otf") format("truetype");
}

@font-face {
    font-family: "CantedFX-Regular";
    src: local("CantedFX-Regular"),
        url("../fonts/CantedFX-Regular.otf") format("truetype");
}


